import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./about.module.scss"

const AboutPage = () => (
  <Layout>
    <SEO title="About" keywords={[`about`, `company`, `architecture`, `tamil nadu`]} />
    <div className="row">
      <div className={`col-sm-12 ${styles.content}`}>
        <p>
          We are an architectural visualization company based in Coimbatore,
          TamilNadu. We strive to deliver optimal results in every project we
          undertake. We believe in making visualizations that display quality.
          Our team works together to produce results keeping client satisfaction
          in mind. Our services include exterior rendering, interior rendering,
          3d animations, VR and AR.
        </p>
      </div>
    </div>
  </Layout>
)

export default AboutPage
